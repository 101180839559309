/**
 * Encapsulates methods for transforming form data into backend format. This is invoked when a form is submitted.
 */

import { isFormulaDataSource, FormulaParser } from '../utils'
import { convertDateTimeToString } from 'utils/Date'
import {
  SHOW_IN_COLUMNS_OR_ROWS_MAPPINGS_ARRAY,
  DECIMAL_SEPARATOR_MAPPING_ARRAY,
  PRECISION_MAPPING_ARRAY,
  DECIMAL_COUNT_MAPPING_ARRAY,
  THOUSAND_SEPARATOR_MAPPING_ARRAY,
  DIMENSION_PRES_TYPE_INCLUDE_DIMENSIONS,
  DIMENSION_PRES_TYPE_DIMENSION_PER_COLUMN,
  DATATYPE_BUDGET,
} from '../constants'

export default class ReportSelectionFEToBETransformer {
  getCompanyDTOStub = () => {
    const def = -1
    return {
      companyId: def,
      budgetId: undefined,
      dimensionValueIds: [],
      dimensionIds: [],
      dimensionTargetId: undefined,
      exchangeRate: undefined,
      useExclusiveDimensionQuery: false,
      useReportingCodesInsteadAccountNumbers: false,
      useGroupDates: false,
      reportingGroupIds: [],
    }
  }
  mapRowsOrColumns = (incomingArray) => {
    let returnValue = incomingArray.map((stringValue) =>
      SHOW_IN_COLUMNS_OR_ROWS_MAPPINGS_ARRAY.findIndex(
        (val) => val === stringValue
      )
    )
    returnValue = returnValue.filter((intValue) => intValue >= 0)
    return returnValue
  }
  transformCompanies = (dataSource) => {
    const transformedCompanies = dataSource.companies.map((tmpCompany) => {
      const transformedCompany = this.getCompanyDTOStub()
      const tmpCompanyId = tmpCompany.id
      transformedCompany.companyId = parseInt(tmpCompanyId, 10)

      transformedCompany.reportingGroupIds =
        dataSource.reportingGroups &&
        dataSource.reportingGroups.get(tmpCompanyId)
          ? dataSource.reportingGroups.get(tmpCompanyId)
          : []

      transformedCompany.dimensionValueIds =
        dataSource.dimensionValues &&
        dataSource.dimensionValues.get(tmpCompanyId)
          ? dataSource.dimensionValues.get(tmpCompanyId)
          : []

      transformedCompany.dimensionIds =
        dataSource.dimensions && dataSource.dimensions.get(tmpCompanyId)
          ? dataSource.dimensions.get(tmpCompanyId)
          : []
      transformedCompany.exchangeRate = tmpCompany.exchangeRate
      transformedCompany.budgetId =
        dataSource.dataType === DATATYPE_BUDGET
          ? dataSource.budgets.get(tmpCompanyId)
          : undefined
      transformedCompany.useExclusiveDimensionQuery =
        tmpCompany.useExclusiveDimensionQuery
      transformedCompany.useReportingCodesInsteadAccountNumbers =
        tmpCompany.useReportingCodesInsteadAccountNumbers
      transformedCompany.useGroupDates = tmpCompany.useGroupDates
      return transformedCompany
    })
    return transformedCompanies
  }
  transformDataSource = (feDs, index) => {
    const beDs = {
      // Must not set whole object because immutable. Set all properties separately
      ...feDs,
      companies: this.transformCompanies(feDs, index),
      formula: this.transformSelectionsToFormula(feDs),
      formulaSelections: isFormulaDataSource(feDs)
        ? {
            comparisonFirstDataSource: feDs.comparisonFirstDataSource,
            comparisonOperator: feDs.comparisonOperator,
            comparisonSecondDataSource: feDs.comparisonSecondDataSource,
          }
        : null,
      start: convertDateTimeToString(
        feDs.start ? feDs.start : '0001-01-01T00:00:00'
      ),
      end: convertDateTimeToString(feDs.end ? feDs.end : '0001-01-01T00:00:00'),
      // isLinked: feDs.isLinked ? feDs.isLinked : false,
      order: feDs.order ? feDs.order : 0,
      dimensionPresentationType:
        feDs.dimensionPresentationType ===
        DIMENSION_PRES_TYPE_INCLUDE_DIMENSIONS
          ? DIMENSION_PRES_TYPE_DIMENSION_PER_COLUMN
          : feDs.dimensionPresentationType,
    }
    return beDs
  }
  transformSelectionsToFormula = (feDs) =>
    FormulaParser.transformSelectionsToFormula({
      comparisonFirstDataSource: feDs.comparisonFirstDataSource,
      comparisonOperator: feDs.comparisonOperator,
      comparisonSecondDataSource: feDs.comparisonSecondDataSource,
    })
  transform = ({ feReportSelection }) => {
    const output = {}
    const input = feReportSelection
    output.reportDataSources = input.reportDataSources?.map((feDs, index) =>
      this.transformDataSource(feDs, index)
    )
    output.name = input.name
    output.showSubTotals = input.showSubTotals
    output.showTotalColumn = input.showTotalColumn
    output.showPercentageColumn = input.showPercentageColumn
    output.dateFormat = input.dateFormat || 'yyyy/MM'
    output.useOverlappingMonths =
      input.dateFormat === 'yyyy/MM' ? input.useOverlappingMonths : false
    output.currencyCode = input.currencyCode
    output.reportSchemeId = input.reportSchemeId
    output.id = input.id
    output.decimalSeparatorType = DECIMAL_SEPARATOR_MAPPING_ARRAY.findIndex(
      (val) => val === input.decimalSeparatorType
    )
    output.precisionType = PRECISION_MAPPING_ARRAY.findIndex(
      (val) => val === input.precisionType
    )
    output.decimals = DECIMAL_COUNT_MAPPING_ARRAY.findIndex(
      (val) => val === input.decimals
    )
    output.thousandSeparatorType = THOUSAND_SEPARATOR_MAPPING_ARRAY.findIndex(
      (val) => val === input.thousandSeparatorType
    )
    output.reportSelectionUsers = input.reportSelectionUsers
    output.showInColumns = input.showInColumns // BE eats strings or integers
    output.showInRows = input.showInRows
    output.type = input.type
    output.customerId = input.customerId
    output.reportSelectionTagIds = input.reportSelectionTagIds
    output.includeLastSchemeLevelAsDataRow =
      input.includeLastSchemeLevelAsDataRow
    output.visualizationOverride = input.visualizationOverride
    return output
  }
}
