export const getFirstFreeNameWithoutCopy = (datasources, name, ignoreId) => {
  const baseName = name?.split(' (copy')?.[0]
  const copysCount = datasources.filter(
    (d) => d.name.startsWith(baseName) && d.id !== ignoreId
  ).length
  for (let i = 0; i <= copysCount; i++) {
    let copyName = `${baseName} (copy ${i})`
    if (i === 0) copyName = baseName
    if (i === 1) copyName = `${baseName} (copy)`
    if (!datasources.find((d) => d.name === copyName && d.id !== ignoreId)) {
      return copyName
    }
  }
  return baseName
}

export const mutilateVisualizationToFitForm = ({
  visualizationOverride,
  form,
  reportSelectionToDisplay,
}) => {
  if (!visualizationOverride?.slice?.measures) return visualizationOverride

  // Remove deleted datasources from visualizationOverride
  const deletedDatasources = reportSelectionToDisplay.reportDataSources.filter(
    (ds) => !form.reportDataSources.some((rds) => rds.id === ds.id)
  )

  const newVisualizationOverride = { ...visualizationOverride }
  newVisualizationOverride.slice.measures = visualizationOverride?.slice?.measures.filter(
    (measure) => {
      if (!measure.formula) {
        return true
      }
      return !deletedDatasources.some((deleted) =>
        measure.formula.toLowerCase().includes(deleted.name.toLowerCase())
      )
    }
  )

  // Add new formulas to measures
  const currentFormulas = form.reportDataSources.filter(
    (ds) => ds.dataType === 'FormulaData'
  )
  const missingFormulas = currentFormulas.filter(
    (formula) =>
      !newVisualizationOverride.slice.measures.some(
        (measure) => measure.formula === formula.formula
      )
  )

  newVisualizationOverride.slice.measures = [
    ...newVisualizationOverride.slice.measures,
    ...missingFormulas.map((formula) => ({
      formula: formula.formula,
      caption: formula.name,
      uniqueName: formula.name,
    })),
  ]

  return newVisualizationOverride
}
